































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

import meQuery from '@/graphql/me/Me.query.gql';
import replyMemberReviewMutation from '@/graphql/memberReview/ReplyMemberReview.mutation.gql';

import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

@Component({
  components: { TheCrossIcon },
  computed: {
    ...mapState(['isReviewFilter']),
  },
  apollo: {
    me: {
      query: meQuery,
    },
  },
})
export default class ReviewData extends Vue {
  @Prop() reviews!: any[];

  clickIndex = -1;
  isMobileReply = -1;
  me: any = {};
  memberReviews: any[] = [];
  reviewComment = '';

  timeFromNow(dt: string) {
    return this.$moment(dt).fromNow();
  }

  enter(element: any) {
    element.style.width = getComputedStyle(element).width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = 'auto';

    const height = getComputedStyle(element).height;

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;
    element.style.height = 0;

    getComputedStyle(element).height;
    setTimeout(() => {
      element.style.height = height;
    });
  }

  afterEnter(element: any) {
    element.style.height = 'auto';
  }

  leave(element: any) {
    element.style.height = getComputedStyle(element).height;
    getComputedStyle(element).height;

    setTimeout(() => {
      element.style.height = 0;
    });
  }

  saveReplay(item: any) {
    this.$apollo
      .mutate({
        mutation: replyMemberReviewMutation,
        variables: {
          id: item.id,
          text: this.reviewComment,
        },
      })
      .then((res: any) => {
        if (res) {
          this.clickIndex = -1;
          this.reviewComment = '';
          this.$emit('reply-success');
        }
      });
  }

  close() {
    this.clickIndex = -1;
    this.reviewComment = '';
  }
}
