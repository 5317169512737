












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { EntityTypes } from '@/helpers/entityType';

import academyMemberReviewsQuery from '@/graphql/memberReview/AcademyMemberReviews.query.gql';
import artFairMemberReviewsQuery from '@/graphql/memberReview/ArtFairMemberReviews.query.gql';
import artSpaceMemberReviewsQuery from '@/graphql/memberReview/ArtSpaceMemberReviews.query.gql';
import eventMemberReviewsQuery from '@/graphql/memberReview/EventMemberReviews.query.gql';
import exhibitionMemberReviewsQuery from '@/graphql/memberReview/ExhibitionMemberReviews.query.gql';
import miniCultivistMemberReviewsQuery from '@/graphql/memberReview/MiniCultivistMemberReviews.query.gql';
import addMemberReviewMutation from '@/graphql/memberReview/AddMemberReview.mutation.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import ReviewData from '@/components/partials/ReviewData.vue';
import AddReview from '@/components/partials/AddReview.vue';
import AppCrossIcon from '@/components/icons/AppCrossIcon.vue';
import { prepareVariablesForSingleEntityQuery } from '@/helpers/graphqlHelper';

@Component({
  components: {
    AppCrossIcon,
    BackHeader,
    ReviewData,
    AddReview,
  },
  metaInfo() {
    let title = this.entity?.title || 'Event';

    if (this.entity?.isPermanentCollection && this.entity?.artSpace) {
      title += `. ${this.entity.artSpace.title}`;
    }

    return {
      title: `Reviews: ${title}`,
    };
  },
  apollo: {
    reviewsEntity: {
      query() {
        if (this.entityType == EntityTypes.academy) {
          return academyMemberReviewsQuery;
        }
        if (this.entityType == EntityTypes.art_fair) {
          return artFairMemberReviewsQuery;
        }
        if (this.entityType == EntityTypes.art_space) {
          return artSpaceMemberReviewsQuery;
        }
        if (this.entityType == EntityTypes.event) {
          return eventMemberReviewsQuery;
        }
        if (this.entityType == EntityTypes.exhibition) {
          return exhibitionMemberReviewsQuery;
        }
        if (this.entityType == EntityTypes.mini_cultivist) {
          return miniCultivistMemberReviewsQuery;
        }
      },
      variables() {
        const { slug } = prepareVariablesForSingleEntityQuery(this.$route.params);

        return { slug };
      },
      result(result: any) {
        if (result.data.reviewsEntity) {
          this.entity = {
            id: result.data.reviewsEntity.id,
            title: result.data.reviewsEntity.title,
            isPermanentCollection: result.data.reviewsEntity.is_permanent_collection || false,
            artSpace: result.data.reviewsEntity.artSpace,
          };
          this.memberReviews = result.data.reviewsEntity.memberReviews;
        }
      },
    },
  },
  computed: {
    ...mapState(['isMobileScreenExclude768', 'isReviewFilter']),
  },
})
export default class DataItemReviewsPage extends Vue {
  @Prop() entityType!: string;

  private activeList = 'latest';
  private showReviewField = false;
  private reviewText = '';
  private isMobileScreenExclude768: boolean;
  private needAddReviewScreen = false;
  public memberReviews: any[] = [];

  public entity: any = null;

  beforeSlideDown(el: HTMLElement) {
    el.style.height = el.scrollHeight + 'px';
  }

  afterSlideDown(el: HTMLElement) {
    el.style.height = '';
  }

  toggleTab(name: string) {
    this.activeList = name;
    if (name === 'all') {
      this.$store.dispatch('reviewToogle', true);
    } else {
      this.$store.dispatch('reviewToogle', false);
    }
  }

  addReview() {
    window.scrollTo(0, 0);

    if (this.isMobileScreenExclude768) {
      this.needAddReviewScreen = true;
    } else {
      this.showReviewField = true;
    }
  }

  saveReview() {
    this.$apollo
      .mutate({
        mutation: addMemberReviewMutation,
        variables: {
          objectType: this.entityType,
          objectId: this.entity.id,
          text: this.reviewText,
        },
      })
      .then(() => {
        this.$toast.error('Please wait confirmation.');
        this.hideReviewField();
      });
  }

  hideReviewField() {
    this.reviewText = '';
    this.showReviewField = false;
  }

  closeAddReview() {
    this.needAddReviewScreen = false;
  }

  onReplySuccess() {
    this.$apollo.queries.reviewsEntity.refetch();
  }
}
