
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

import addMemberReviewMutation from '@/graphql/memberReview/AddMemberReview.mutation.gql';

import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

@Component({
  components: { TheCrossIcon },
  computed: {
    ...mapState(['destinationReview']),
  },
})
export default class AddReview extends Vue {
  @Prop() entityType!: string;
  @Prop() entityId!: any;

  comment = '';

  created() {
    this.$store.dispatch('disableScroll');
  }

  close() {
    this.$emit('close');
    this.$store.dispatch('enableScroll');
  }

  saveReview() {
    this.$apollo
      .mutate({
        mutation: addMemberReviewMutation,
        variables: {
          objectType: this.entityType,
          objectId: this.entityId,
          text: this.comment,
        },
      })
      .then((result: any) => {
        if (result.data?.addMemberReview) {
          this.$toast.error('Please wait confirmation.');
          this.close();
        }
      });
  }
}
